<template>
    <div>
        <v-divider v-if="currentInstanceRunningApps.length"></v-divider>
        <div v-for="app in currentInstanceRunningApps" :key="app.aid">
            <v-menu rounded="0" :close-on-content-click="false" offset-x open-on-hover :close-delay="closingDelay">
                <template v-slot:activator="{ on }">
                    <v-list-item
                        v-on="on"
                        :to="{
                            name: 'app-open',
                            params: { oid: $route.params.oid, sid: $route.params.sid, iid: $route.params.iid, snid: $route.params.snid, aid: app.aid }
                        }"
                    >
                        <v-list-item-icon v-if="app.app_type !== undefined">
                            <div class="d-flex flex-column">
                                <img width="35" height="35" :src="getAppLink(app.app_type)" />
                                <div class="mx-n3 mt-1 mb-n2">
                                    <TheSideBarResourceInfo :currentApp="getAppInfo(app.aid)" />
                                </div>
                            </div>
                        </v-list-item-icon>
                        <v-list-item-title>Applications</v-list-item-title>
                    </v-list-item>
                </template>
                <v-card flat>
                    <v-card-title>
                        <span class="font-weight-bold primary--text subtitle-2 text-uppercase">{{ app.long_id }}</span>
                    </v-card-title>
                    <v-card-text>
                        <v-divider class="mb-1"></v-divider>
                        <div class="d-flex align-center justify-space-between">
                            Memory limit:
                            <div>
                                <span class="font-weight-medium ml-1">{{ getAppInfo(app.aid).limit_memory }}</span>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-icon color="primary" class="ml-2" dark v-on="on" small>
                                            info
                                        </v-icon>
                                    </template>
                                    <span>If this is breached, the app is restarted. There can be some delay in reporting.</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <div style="width:100%" class="d-flex flex-column">
                            <v-btn small @click="showScaleModal(app.aid)" color="secondary" class="mb-1" block>
                                <v-icon small class="mr-1">open_in_full</v-icon>Scale Resources
                            </v-btn>
                            <v-btn block @click="stopDialog = true" text small outlined> <v-icon small class="mr-1">stop</v-icon>Stop</v-btn>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-menu>
            <v-dialog v-model="stopDialog" max-width="500" persistent>
                <v-card>
                    <v-card-title>
                        <div class="d-flex align-center text-uppercase title primary--text">
                            <v-icon class="mr-1">stop</v-icon>
                            Stop Application
                        </div>
                    </v-card-title>
                    <v-divider class="mb-1"></v-divider>
                    <v-card-text>
                        Are you sure you want to stop the application <span class="font-weight-bold">{{ app.long_id }}</span
                        >?
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text color="primary" class="mr-1" @click="stopDialog = false">Cancel</v-btn>
                        <v-btn color="error" text @click="stopApp(app.aid)">
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { appTypeAndImageLink } from '@/mixins/appTypeAndImage'
const TheSideBarResourceInfo = () => import('./TheSideBarResourceInfo')

export default {
    mixins: [appTypeAndImageLink],
    components: {
        TheSideBarResourceInfo
    },
    data() {
        return {
            closingDelay: 100,
            stopDialog: false
        }
    },
    computed: {
        ...mapGetters('snapshotStore', ['isDevelopment']),
        ...mapState('snapshotStore', ['applications', 'applicationsFetching']),
        ...mapGetters('spaceStore', ['isSpaceAdmin', 'currentSpaceType']),
        ...mapGetters('appStore', ['runningDeployments', 'startingDeployments']),
        ...mapGetters('instanceStore', ['isInstanceEditor', 'isMasterInstance']),
        ...mapState(['userInfo']),
        currentInstanceRunningApps: function() {
            const runningAppIds = this.runningDeployments.map(app => app.aid.toString())
            const launchingAppIds = this.startingDeployments.map(app => app.aid.toString())
            const appIds = runningAppIds.concat(launchingAppIds)
            return this.applications.filter(app => appIds.includes(app.aid.toString()))
        }
    },
    methods: {
        stopApp: function(aid) {
            this.$store.dispatch('appStore/stopApp', { aid })
            this.$router.push({
                name: 'snapshot-applications',
                params: {
                    oid: this.$route.params.oid,
                    sid: this.$route.params.sid,
                    iid: this.$route.params.iid,
                    snid: this.$route.params.snid
                }
            })
            this.stopDialog = false
        },
        showScaleModal(aid) {
            this.$router.push({
                name: 'app-open',
                params: {
                    oid: this.$route.params.oid,
                    sid: this.$route.params.sid,
                    iid: this.$route.params.iid,
                    snid: this.$route.params.snid,
                    aid: aid
                },
                query: { scale: 1 }
            })
        },
        getAppInfo(aid) {
            return this.runningDeployments.concat(this.startingDeployments).find(a => {
                return a.aid.toString() === aid.toString()
            })
        }
    }
}
</script>
