var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.currentInstanceRunningApps.length ? _c("v-divider") : _vm._e(),
      _vm._l(_vm.currentInstanceRunningApps, function(app) {
        return _c(
          "div",
          { key: app.aid },
          [
            _c(
              "v-menu",
              {
                attrs: {
                  rounded: "0",
                  "close-on-content-click": false,
                  "offset-x": "",
                  "open-on-hover": "",
                  "close-delay": _vm.closingDelay
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function(ref) {
                        var on = ref.on
                        return [
                          _c(
                            "v-list-item",
                            _vm._g(
                              {
                                attrs: {
                                  to: {
                                    name: "app-open",
                                    params: {
                                      oid: _vm.$route.params.oid,
                                      sid: _vm.$route.params.sid,
                                      iid: _vm.$route.params.iid,
                                      snid: _vm.$route.params.snid,
                                      aid: app.aid
                                    }
                                  }
                                }
                              },
                              on
                            ),
                            [
                              app.app_type !== undefined
                                ? _c("v-list-item-icon", [
                                    _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c("img", {
                                          attrs: {
                                            width: "35",
                                            height: "35",
                                            src: _vm.getAppLink(app.app_type)
                                          }
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "mx-n3 mt-1 mb-n2" },
                                          [
                                            _c("TheSideBarResourceInfo", {
                                              attrs: {
                                                currentApp: _vm.getAppInfo(
                                                  app.aid
                                                )
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  ])
                                : _vm._e(),
                              _c("v-list-item-title", [_vm._v("Applications")])
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  true
                )
              },
              [
                _c(
                  "v-card",
                  { attrs: { flat: "" } },
                  [
                    _c("v-card-title", [
                      _c(
                        "span",
                        {
                          staticClass:
                            "font-weight-bold primary--text subtitle-2 text-uppercase"
                        },
                        [_vm._v(_vm._s(app.long_id))]
                      )
                    ]),
                    _c(
                      "v-card-text",
                      [
                        _c("v-divider", { staticClass: "mb-1" }),
                        _c(
                          "div",
                          {
                            staticClass:
                              "d-flex align-center justify-space-between"
                          },
                          [
                            _vm._v(" Memory limit: "),
                            _c(
                              "div",
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-weight-medium ml-1" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.getAppInfo(app.aid).limit_memory
                                      )
                                    )
                                  ]
                                ),
                                _c(
                                  "v-tooltip",
                                  {
                                    attrs: { bottom: "" },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "activator",
                                          fn: function(ref) {
                                            var on = ref.on
                                            return [
                                              _c(
                                                "v-icon",
                                                _vm._g(
                                                  {
                                                    staticClass: "ml-2",
                                                    attrs: {
                                                      color: "primary",
                                                      dark: "",
                                                      small: ""
                                                    }
                                                  },
                                                  on
                                                ),
                                                [_vm._v(" info ")]
                                              )
                                            ]
                                          }
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  },
                                  [
                                    _c("span", [
                                      _vm._v(
                                        "If this is breached, the app is restarted. There can be some delay in reporting."
                                      )
                                    ])
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        )
                      ],
                      1
                    ),
                    _c("v-card-actions", [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex flex-column",
                          staticStyle: { width: "100%" }
                        },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "mb-1",
                              attrs: {
                                small: "",
                                color: "secondary",
                                block: ""
                              },
                              on: {
                                click: function($event) {
                                  return _vm.showScaleModal(app.aid)
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-1", attrs: { small: "" } },
                                [_vm._v("open_in_full")]
                              ),
                              _vm._v("Scale Resources ")
                            ],
                            1
                          ),
                          _c(
                            "v-btn",
                            {
                              attrs: {
                                block: "",
                                text: "",
                                small: "",
                                outlined: ""
                              },
                              on: {
                                click: function($event) {
                                  _vm.stopDialog = true
                                }
                              }
                            },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "mr-1", attrs: { small: "" } },
                                [_vm._v("stop")]
                              ),
                              _vm._v("Stop")
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ])
                  ],
                  1
                )
              ],
              1
            ),
            _c(
              "v-dialog",
              {
                attrs: { "max-width": "500", persistent: "" },
                model: {
                  value: _vm.stopDialog,
                  callback: function($$v) {
                    _vm.stopDialog = $$v
                  },
                  expression: "stopDialog"
                }
              },
              [
                _c(
                  "v-card",
                  [
                    _c("v-card-title", [
                      _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center text-uppercase title primary--text"
                        },
                        [
                          _c("v-icon", { staticClass: "mr-1" }, [
                            _vm._v("stop")
                          ]),
                          _vm._v(" Stop Application ")
                        ],
                        1
                      )
                    ]),
                    _c("v-divider", { staticClass: "mb-1" }),
                    _c("v-card-text", [
                      _vm._v(" Are you sure you want to stop the application "),
                      _c("span", { staticClass: "font-weight-bold" }, [
                        _vm._v(_vm._s(app.long_id))
                      ]),
                      _vm._v("? ")
                    ]),
                    _c(
                      "v-card-actions",
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "mr-1",
                            attrs: { text: "", color: "primary" },
                            on: {
                              click: function($event) {
                                _vm.stopDialog = false
                              }
                            }
                          },
                          [_vm._v("Cancel")]
                        ),
                        _c(
                          "v-btn",
                          {
                            attrs: { color: "error", text: "" },
                            on: {
                              click: function($event) {
                                return _vm.stopApp(app.aid)
                              }
                            }
                          },
                          [_vm._v(" Confirm ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }